






































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

@Component({
  inheritAttrs: true,
  components: {}
})

export default class SchedulesComponent extends TSXComponent<void> {

  @Prop({ required: false, default: [] })
  schedules!: any;

  clickOutside() {
    this.$emit("close");
  }

  convertEmailsToList(email) {
    return email.length ? email.join(", ") : '';
  }

}
